<template>
  <div>
    <div
      class="custom-space-painel container space-painel mb-6"
      style="margin-top: 5px !important">
      <span class="titulo-painel">Servidor</span>
      <div
        class="flex flex-wrap justify-content-center md:justify-content-start">
        <div
          v-for="(item, index) in menu"
          :id="'card-servidor-' + item.to.name"
          :key="index"
          class="mr-2 md:mr-5"
          :class="{ disabled: item.isDisabled }">
          <div class="main-part" @click="redirecionar(item.to.name)">
            <div class="cpanel animation border">
              <div class="icon-part" :class="{ aviso: item.aviso }">
                <i :class="item.icon"></i>
                <h5
                  :aria-label="item.label"
                  style="margin-top: 30px; padding: 5px">
                  {{ item.label }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="custom-space-painel container space-painel">
      <span class="titulo-painel">Serviços</span>
      <div
        class="flex flex-wrap justify-content-center md:justify-content-start">
        <div
          v-for="(item, index) in servicosList"
          :id="'card-servidor-' + item.url"
          :key="index"
          class="mr-2 md:mr-5">
          <div class="main-part" @click="redirecionarParaLinkExterno(item.url)">
            <div class="cpanel animation border">
              <div class="icon-part">
                <i :class="item.icon"></i>
                <h5
                  :aria-label="item.label"
                  style="margin-top: 30px; padding: 5px">
                  {{ item.label }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { watch } from 'vue-demi'
import ServidorService from '@/service/ServidorService'
import MatriculaService from '@/service/MatriculaService'
import { matriculaStore } from '@/stores/matricula'

export default {
  setup() {
    const store = new matriculaStore()
    return { store }
  },

  data() {
    return {
      menu: [],
      servidor: {},
      servicosList: [],
    }
  },

  created() {
    this.servidorService = new ServidorService(this.$http)
    this.matriculaService = new MatriculaService(this.$http)

    if (this.store.matriculaSelecionada.id) {
      //this.carregarServicos(this.store.matriculaSelecionada.id)
    }

    watch(
      () => this.store.matriculaSelecionada,
      (state) => {
        if (state != null) {
          //this.carregarServicos(state.id)
        }
      },
      { deep: true },
    )
  },

  mounted() {
    this.carregarServidor()
  },
  methods: {
    async carregarMenu() {
      try {
        const res = await this.matriculaService.getMatriculasUsuarioLogado()

        const sead1or2 = res.some(
          (item) =>
            item.entidadeNome === 'SEAD 1' || item.entidadeNome === 'SEAD 2',
        )

        const outraEntidade = res.some(
          (item) =>
            item.entidadeNome !== 'SEAD 1' && item.entidadeNome !== 'SEAD 2',
        )
        const diretasDisabled = !sead1or2
        const indiretasDisabled = sead1or2 && !outraEntidade

        this.menu = [
          {
            label: 'Dados Pessoais (Diretas)',
            icon: 'fa fa-user',
            isDisabled: diretasDisabled,
            to: { name: 'dados-pessoais' },
          },
          {
            label: 'Dados Funcionais (Diretas)',
            icon: 'fa fa-bars',
            isDisabled: diretasDisabled,
            to: { name: 'dados-funcionais' },
          },
          {
            label: 'Dados Cadastrais (Indiretas)',
            icon: 'fa fa-user',
            isDisabled: indiretasDisabled,
            to: { name: 'dados-cadastrais' },
          },
          {
            label: 'Dependentes (Diretas)',
            icon: 'fa fa-users',
            isDisabled: diretasDisabled,
            to: { name: 'dependentes' },
          },
          {
            label: 'Histórico Funcional (Diretas)',
            icon: 'fa fa-history',
            isDisabled: diretasDisabled,
            to: { name: 'historico-funcional' },
          },
          {
            label: 'Ficha Financeira',
            icon: 'fa fa-file',
            isDisabled: false,
            to: { name: 'ficha-financeira' },
          },
          {
            label: 'Contracheque',
            icon: 'fa fa-id-card',
            to: { name: 'ContraChequeLista' },
          },
          {
            label: 'Declaração de Rendimentos',
            icon: 'fa fa-list',
            to: { name: 'rendimentos' },
          },
          {
            label: 'Código de Ética',
            icon: 'fas fa-balance-scale',
            to: { name: 'codigo-etica' },
          },
          {
            label: 'Serviços Digitais',
            icon: 'fas fa-list',
            to: { name: 'servicosDigitais' },
          },
        ]
      } catch (error) {
        console.error('Erro ao carregar o menu:', error)
      }
    },

    redirecionar(data) {
      if (!this.isDisabled) {
        this.$router.push({ name: data })
      }
    },
    carregarServidor() {
      this.servidorService
        .getDadosServidor()
        .then((res) => {
          this.servidor = res
          this.carregarMenu()
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
          this.$router.push('/logout')
        })
    },
    redirecionarParaLinkExterno(link) {
      window.location.href = link
    },
  },
}
</script>
<style type="text/css">
body {
  background-color: #e3e4e3;
}

.aviso {
  color: red;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.space-container-top {
  margin-top: 5px !important;
}

.title-consig {
  margin-top: 50px;
}

.custom-space-painel {
  margin-top: -10px !important;
}

.titulo-painel {
  font-size: 22px;
  margin-bottom: -50px;
}

.space-painel {
  margin-top: -0px !important;
}

.border {
  border-radius: 12%;
}

.main-part {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0px 0px;
}

.cpanel {
  width: 11rem;
  display: inline-block;
  background-color: #ffffff;
  color: #4b4f50;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 50px;
  height: 140px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.icon-part {
  position: relative;
}

.icon-part i {
  font-size: 32px;
  padding: 10px;
  border: 0.1px solid #c9c9c9;
  background-color: #ffffff;
  border-radius: 100%;
  margin-top: -20px;
}

.icon-part p {
  margin: 0px;
  background-color: #ffffff;
}

.animation:hover {
  transform: scale(1.1);
}
</style>
